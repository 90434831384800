import { renderSlot as _renderSlot, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "mo-search-view"
}
const _hoisted_2 = { class: "mo-search-view__content" }
const _hoisted_3 = { class: "mo-search-view__input-wrap" }
const _hoisted_4 = ["placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_i_search = _resolveComponent("i-search")!
  const _component_i_close = _resolveComponent("i-close")!
  const _component_search_view = _resolveComponent("search-view")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_Transition, null, {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "default", {
          focus: () => $setup.focus()
        })
      ]),
      _: 3
    }),
    _createVNode(_Transition, null, {
      default: _withCtx(() => [
        (!$setup.show)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createElementVNode("div", {
                class: "mo-search-view__overlay",
                onClick: $setup.unfocus
              }),
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_search_view, {
                  "hide-tabs": $props.hideTabs,
                  search: $setup.search,
                  "field-in-focus": $setup.fieldInFocus,
                  "default-tab": $props.defaultTab,
                  "onQuery:update": $setup.queryUpdateHandler
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_3, [
                      _withDirectives(_createElementVNode("input", {
                        class: "mo-search-view__input",
                        ref: "input",
                        placeholder: _ctx.$t("Search"),
                        type: "search",
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.search) = $event)),
                        onFocus: $setup.setFocus,
                        onBlur: $setup.removeFocus
                      }, null, 40, _hoisted_4), [
                        [_vModelText, $setup.search]
                      ]),
                      _createVNode(_component_i_search, { class: "mo-search-view__search-button" }),
                      _createVNode(_component_i_close, {
                        class: "mo-search-view__close-button",
                        onClick: _withModifiers($setup.unfocus, ["prevent"])
                      })
                    ])
                  ]),
                  _: 1
                }, 8, ["hide-tabs", "search", "field-in-focus", "default-tab"])
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}